import React, { FC } from 'react';
//
import {
    ToggleButtonGroup,
    ToggleButton,
    Box,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//
import { USER_TYPES } from '../../../constants.ts';

type AuthToggleButtonGroupProps = {
    userType: USER_TYPES;
    handleUserTypeChange: (
        event: React.MouseEvent<HTMLElement>,
        newUserType: USER_TYPES
    ) => void;
    USER_TYPES: {
        investor: USER_TYPES;
        business_owner: USER_TYPES;
    };
};

export const AuthToggleButtonGroup: FC<AuthToggleButtonGroupProps> = ({
    userType,
    handleUserTypeChange,
    USER_TYPES,
}) => {
    return (
        <ToggleButtonGroup
            value={userType}
            exclusive
            onChange={handleUserTypeChange}
            sx={{
                width: '100%',
                display: 'flex',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.06)',
            }}
        >
            <ToggleButton
                value={USER_TYPES.investor}
                sx={{
                    textTransform: 'none',
                    width: '100%',
                }}
            >
                {userType === USER_TYPES.investor && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                        }}
                    >
                        <CheckCircleIcon />
                    </Box>
                )}
                <Typography
                    sx={{
                        fontFamily: 'Manrope',
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '26px',
                    }}
                >
                    I want to invest
                </Typography>
            </ToggleButton>
            <ToggleButton
                value={USER_TYPES.business_owner}
                sx={{
                    textTransform: 'none',
                    width: '100%',
                }}
            >
                {userType === USER_TYPES.business_owner && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                        }}
                    >
                        <CheckCircleIcon />
                    </Box>
                )}
                <Typography
                    sx={{
                        fontFamily: 'Manrope',
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '26px',
                    }}
                >
                    I want to borrow
                </Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
