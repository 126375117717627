import { Avatar, Typography } from '@mui/material';
import MichaelDavisSrc from 'Assets/images/Michael Davis.png';

export const AuthSideInfo = () => {
    return (
        <div className="w-1/2 side hidden lg:block bg-gradient-to-tr side-gradient text-white">
            <div className="px-20 py-28">
                <div className="flex flex-col gap-8">
                    <Typography variant="spectral_32">
                        Homegrown helps the best neighborhood businesses open up
                        more locations by providing a single source of
                        non-dilutive expansion capital without requiring
                        personal guarantees.
                    </Typography>
                    <Typography variant="manrope_16" component="div">
                        We allow owners (who have at least two locations of a
                        brand) to trade a small percentage of future revenue
                        from their entire business in exchange for large amounts
                        of growth capital now.
                        <Typography variant="manrope_16" component="div">
                            <br />
                            We started Homegrown because we believe that the{' '}
                            <strong>
                                best neighborhood entrepreneurs deserve better,
                                faster, friendlier, and more straight-forward
                                financing options
                            </strong>{' '}
                            that are currently unavailable to them. We also know
                            that (accredited) investors in every city want to
                            support the growth of neighborhood businesses{' '}
                            <strong>
                                while also earning wonderful, monthly returns.
                            </strong>{' '}
                            Many investment products with high returns force you
                            to lock up capital for many years without seeing a
                            penny of liquidity. We are the complete opposite of
                            that.
                        </Typography>
                        <Typography variant="manrope_16" component="div">
                            <br />
                            We unlock superior returns through thoughtful
                            curation & diligence of investment opportunities.
                            When something hits the Homegrown platform, it's
                            because we believe we're backing an emerging empire.
                            Thank you for joining us on this journey. Let's go
                            cause more growth to happen.
                        </Typography>
                    </Typography>
                    <div className="flex flex-row gap-4 items-center">
                        <Avatar
                            sx={{
                                width: '108px',
                                height: '108px',
                            }}
                            src={MichaelDavisSrc}
                        />
                        <div className="py-6">
                            <Typography
                                variant="manrope_20"
                                sx={{
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                }}
                            >
                                Michael Davis
                            </Typography>
                            <Typography
                                variant="manrope_16"
                                component="div"
                                sx={{
                                    lineHeight: '28px',
                                }}
                            >
                                Co-founder & CEO of Homegrown
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
