import React, { FC, useRef, ChangeEvent, DragEvent } from 'react';
//
import { Box, Typography, LinearProgress, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
//
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';

const MUIFileRow: FC<{
    file: File;
    onDelete: () => void;
}> = ({ file, onDelete }) => (
    <Box
        sx={{
            display: 'flex',
            marginTop: '8px',
            gap: '16px',
            padding: '16px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            alignItems: 'center',
            maxWidth: '100%',
        }}
    >
        <Box
            sx={{
                padding: '10px 12px',
            }}
        >
            <UploadFileIcon />
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}
        >
            <Typography variant="roboto_16">{file.name}</Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    marginBottom: '4px',
                }}
            >
                <Typography variant="roboto_14">
                    {(file.size / 1024).toFixed(2)} KB
                </Typography>
                <Typography variant="roboto_14">•</Typography>
                <Typography variant="roboto_14">Complete</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={100} />
            </Box>
        </Box>
        <IconButton onClick={onDelete} sx={{ flexShrink: 0 }}>
            <DeleteIcon
                color="primary"
                sx={{
                    width: '20px',
                    height: '20px',
                }}
            />
        </IconButton>
    </Box>
);

export const MUIFilesDragAndDrop: FC<{
    files: File[];
    onFilesAdded: (files: File[]) => void;
    onFileDelete: (file: File) => void;
    id: string;
    maxFiles?: number;
}> = ({ files, onFilesAdded, onFileDelete, id, maxFiles }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { showError } = useShowToast();

    const handleFilesAdded = (newFiles: File[]) => {
        const validFiles: File[] = [];
        newFiles.forEach((file) => {
            const fileType = file.type;
            const fileSize = file.size;
            const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            const maxSize = 3 * 1024 * 1024;

            if (!validTypes.includes(fileType)) {
                showError(
                    'Invalid file type. Only PDF, JPG, and PNG are allowed.'
                );
            } else if (fileSize > maxSize) {
                showError('File size exceeds 3MB.');
            } else if (files.some((f) => f.name === file.name)) {
                showError('This file is already uploaded.');
            } else if (maxFiles && files.length >= maxFiles) {
                showError(`You can only upload up to ${maxFiles} files.`);
            } else {
                validFiles.push(file);
            }
        });

        if (validFiles.length > 0) {
            onFilesAdded(validFiles);
        }
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const newFiles = Array.from(event.target.files || []);
        handleFilesAdded(newFiles);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragAndDrop = (event: DragEvent) => {
        event.preventDefault();
        const newFiles = Array.from(event.dataTransfer.files);
        handleFilesAdded(newFiles);
    };

    return (
        <Box>
            <Box
                sx={{
                    padding: '24px 78px',
                    marginTop: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px dashed rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                    textAlign: 'center',
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDragAndDrop}
            >
                <Box
                    sx={{
                        padding: '10px 12px',
                        marginBottom: '8px',
                    }}
                >
                    <UploadFileIcon />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '4px',
                        marginBottom: '8px',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        component="label"
                        htmlFor={`file-upload-${id}`}
                        variant="manrope_16"
                        sx={{
                            fontWeight: '600',
                            lineHeight: '28px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        Click to upload
                    </Typography>
                    <Typography variant="roboto_16">
                        or drag and drop
                    </Typography>
                </Box>
                <Typography
                    variant="roboto_14"
                    sx={{
                        fontWeight: 400,
                    }}
                >
                    PDF, JPG, or PNG (max. 3MB)
                </Typography>
                <input
                    ref={fileInputRef}
                    id={`file-upload-${id}`}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
            </Box>
            {files.map((file) => (
                <MUIFileRow
                    key={file.name}
                    file={file}
                    onDelete={() => onFileDelete(file)}
                />
            ))}
        </Box>
    );
};
