import React, { useState, useLayoutEffect, useEffect } from 'react';
//
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import {
    Backdrop,
    Box,
    Button,
    Card,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
//
import HomegrownSVG from 'Assets/homegrown.svg';
import EntityRafikiSVG from 'Assets/images/EntityRafiki.svg';
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import { formatSSN, validateEmail } from 'Helpers/utils.ts';
import { ENTITYENDPOINTS } from 'Services/entity/entityApi.ts';
import { MUIFilesDragAndDrop } from 'Components/MUIDragAndDrop.tsx';
import {
    citizenshipOptions,
    idOptions,
    usStatesOptions,
} from 'Components/onboarding/OnboardingOptions.tsx';
import { INVESTOR_SECONDARY } from '../../app-constants.ts';
import ClientLoader from './components/ClientLoader.tsx';
import { SecondaryInvestorInviteComplete } from './SecondaryInvestorInviteComplete.tsx';
import { NoSecondaryInvestorInvite } from './NoSecondaryInvestorInvite.tsx';

type FormValues = {
    legal_name: string;
    phone_number: string;
    email: string;
    ssn_id: string;
    date_of_birth: string;
    us_citizenship_status: string;
    government_id: {
        id_type: string;
        issuing_state: string;
        id_number: string;
        issue_date: string;
        expiration_date: string;
    };
};

type InvestorData = {
    legal_name: string;
    phone_number: string;
    email: string;
    entity_name: string;
};

export const SecondaryInvestorInvite = () => {
    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');
    const { showSuccess, showError } = useShowToast();

    const [data, setData] = useState<InvestorData | null>(null);
    const [proofDocsFiles, setProofDocsFiles] = useState<File[]>([]);
    const [idFiles, setIdFiles] = useState<File[]>([]);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCodeAvailable, setIsCodeAvailable] = useState(true);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormValues>();

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.getSecondaryInvestorDetails}`;
                const response = await axios.get(url, {
                    params: { code },
                });
                setIsLoading(false);
                setData(response.data);
            } catch (error: unknown) {
                if (error && typeof error === 'object' && 'response' in error) {
                    const axiosError = error as {
                        response: { data: { status: string } };
                    };
                    if (
                        axiosError.response.data?.status ===
                        'The invite code already used'
                    ) {
                        setIsLoading(false);
                        setIsComplete(true);
                    }
                    if (
                        axiosError.response.data?.status ===
                        'The invite code does not exist'
                    ) {
                        setIsCodeAvailable(false);
                        setIsLoading(false);
                    }
                }
                setIsLoading(false);
            }
        };

        if (code && !isComplete && !data) {
            fetchData();
        }
    }, [code, showError, isComplete, data]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'ssn_id') {
                const ssnValue = value.ssn_id || '';
                const formattedSSN = formatSSN(ssnValue);
                if (ssnValue !== formattedSSN) {
                    setValue('ssn_id', formattedSSN, { shouldValidate: true });
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    const onSubmit = async (formData: FormValues) => {
        const { legal_name, ...rest } = formData;
        const [first_name, last_name] = legal_name.split(' ', 2);

        if (!code) {
            console.error('Code is null or undefined');
            return;
        }

        const submissionData = {
            code: code,
            first_name,
            last_name,
            ...rest,
        };

        setIsLoading(true);

        try {
            const saveUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.saveSecondaryInvestor}`;
            await axios.post(saveUrl, submissionData);

            const uploadDocument = async (file: File, documentType: string) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('code', code);
                formData.append('type', documentType);

                const uploadUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.postSecondaryInvestorDocument}`;
                await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            };

            await Promise.all([
                ...idFiles.map((file) => uploadDocument(file, 'issued_id')),
                ...proofDocsFiles.map((file) => uploadDocument(file, 'proof')),
            ]);

            const completeUrl = `${INVESTOR_SECONDARY}/${ENTITYENDPOINTS.completeSecondaryInvestorOnboarding}`;
            await axios.post(completeUrl, { code });

            showSuccess(
                'All data and documents have been successfully saved and uploaded.'
            );
            setIsComplete(true);
        } catch (error) {
            showError('Failed to submit investor data.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!data && (!isComplete || !isCodeAvailable)) return <ClientLoader />;
    if (isComplete) return <SecondaryInvestorInviteComplete />;
    if (!isCodeAvailable) return <NoSecondaryInvestorInvite />;

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: 0.7,
                }}
                open={isLoading || !data}
            >
                <ClientLoader />
            </Backdrop>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        padding: '20px 24px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '32px',
                    }}
                >
                    <img
                        src={HomegrownSVG}
                        alt="Homegrown Logo"
                        width={188}
                        height={32}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '32px',
                        flexDirection: 'column',
                        maxWidth: '720px',
                        width: '100%',
                        margin: '0 auto',
                        padding: '0 24px',
                    }}
                >
                    <Typography
                        variant="spectral_32"
                        sx={{ textAlign: 'center' }}
                    >
                        Investor Verification Form
                    </Typography>
                    <img src={EntityRafikiSVG} alt="Entity Rafiki Logo" />
                    <Typography variant="manrope_14">
                        With your permission, Homegrown will securely access,
                        process, and share your requested data with our
                        financial partners in order to validate your ownership
                        stake in {data?.entity_name}.
                    </Typography>
                    <Card
                        variant="outlined"
                        sx={{ borderRadius: 4, width: '100%' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '8px 16px',
                                gap: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}
                            >
                                <SecurityIcon />
                                <Typography variant="manrope_14">
                                    Your provided data is secured
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px',
                                }}
                            >
                                <PersonIcon />
                                <Typography variant="manrope_14">
                                    Your data will only be used with your
                                    permission
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            width: '100%',
                        }}
                    >
                        <Typography variant="manrope_12">
                            Basic information
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            label="Legal name"
                            variant="filled"
                            size="medium"
                            defaultValue={data?.legal_name || ''}
                            {...register('legal_name')}
                        />
                        <TextField
                            inputProps={{
                                maxLength: 20,
                            }}
                            required
                            fullWidth
                            type="tel"
                            placeholder="+0(000)000-0000"
                            label="Phone number"
                            variant="filled"
                            size="medium"
                            defaultValue={data?.phone_number || ''}
                            {...register('phone_number')}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Email address"
                            variant="filled"
                            size="medium"
                            error={!!errors.email}
                            defaultValue={data?.email || ''}
                            helperText={errors.email?.message}
                            {...register('email', {
                                validate: validateEmail,
                            })}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                inputProps={{
                                    maxLength: 11,
                                }}
                                required
                                fullWidth
                                label="Social Security Number (SSN)"
                                variant="filled"
                                size="medium"
                                {...register('ssn_id')}
                            />
                            <TextField
                                required
                                fullWidth
                                select
                                label="Citizenship status"
                                variant="filled"
                                size="medium"
                                defaultValue=""
                                {...register('us_citizenship_status')}
                            >
                                {citizenshipOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Typography variant="manrope_12">
                            Government ID
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                select
                                label="ID type"
                                variant="filled"
                                size="medium"
                                defaultValue=""
                                {...register('government_id.id_type')}
                            >
                                {idOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                required
                                fullWidth
                                select
                                label="Issuing state"
                                variant="filled"
                                size="medium"
                                defaultValue=""
                                {...register('government_id.issuing_state')}
                            >
                                {usStatesOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                label="ID number"
                                variant="filled"
                                size="medium"
                                {...register('government_id.id_number')}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                label="Birthdate"
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('date_of_birth')}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: '8px',
                            }}
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                label="Issue date"
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('government_id.issue_date')}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                label="Expiration date"
                                variant="filled"
                                size="medium"
                                type="date"
                                {...register('government_id.expiration_date')}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Typography variant="manrope_12">
                                Upload issue ID (up to 2)
                            </Typography>
                            <MUIFilesDragAndDrop
                                id="issue-id"
                                files={idFiles}
                                onFilesAdded={(newFiles) =>
                                    setIdFiles((prevFiles) => [
                                        ...prevFiles,
                                        ...newFiles,
                                    ])
                                }
                                onFileDelete={(file) =>
                                    setIdFiles((prevFiles) =>
                                        prevFiles.filter((f) => f !== file)
                                    )
                                }
                                maxFiles={2}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}
                        >
                            <Typography variant="manrope_12">
                                Upload accreditation (up to 2)
                            </Typography>
                            <MUIFilesDragAndDrop
                                id="proof-docs"
                                files={proofDocsFiles}
                                onFilesAdded={(newFiles) =>
                                    setProofDocsFiles((prevFiles) => [
                                        ...prevFiles,
                                        ...newFiles,
                                    ])
                                }
                                onFileDelete={(file) =>
                                    setProofDocsFiles((prevFiles) =>
                                        prevFiles.filter((f) => f !== file)
                                    )
                                }
                                maxFiles={2}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="manrope_14" component="div">
                            By pressing Submit, you agree to Homegrown’s{' '}
                            <Typography
                                variant="manrope_14"
                                component="span"
                                role="button"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                }}
                            >
                                Terms & Conditions
                            </Typography>
                            , and{' '}
                            <Typography
                                variant="manrope_14"
                                component="span"
                                role="button"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                }}
                            >
                                Privacy Policy
                            </Typography>
                            .
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginBottom: '24px',
                            width: '100%',
                        }}
                    >
                        <Button fullWidth variant="contained" type="submit">
                            Submit
                        </Button>
                    </Box>
                </Box>
            </form>
        </>
    );
};
